import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NextBtnStateService {
  private disableNextBtnSubject = new BehaviorSubject<boolean>(true);

  setDisableNextBtn(value: boolean): void {
    this.disableNextBtnSubject.next(value);
  }

  getDisableNextBtn() {
    return this.disableNextBtnSubject.asObservable();
  }
}
