<div class="wrapper" [ngSwitch]="fileType">

    <app-phonebook-editor 
        *ngSwitchCase="'standard-phonebook'"
        [crmtFile]="file">
    </app-phonebook-editor>

    <app-phonebook-editor 
        *ngSwitchCase="'extended-phonebook'"
        [crmtFile]="file">
    </app-phonebook-editor>

    <app-sms-database-editor
        *ngSwitchCase="'sms-database'"
        [crmtFile]="file">
    </app-sms-database-editor>

    <app-uus-database-editor
        *ngSwitchCase="'uus-database'"
        [crmtFile]="file">
    </app-uus-database-editor>

    <app-cab-radio-parameters-editor
        *ngSwitchCase="'cab-radio-parameters'"
        [crmtFile]="file">
    </app-cab-radio-parameters-editor>

    <app-attached-systems-parameters-editor
        *ngSwitchCase="'attached-systems-parameters'"
        [crmtFile]="file">
    </app-attached-systems-parameters-editor>

    <app-train-crew-parameters-editor
        *ngSwitchCase="'train-crew-parameters'"
        [crmtFile]="file">
    </app-train-crew-parameters-editor>

    <app-period-timer-parameters-editor
        *ngSwitchCase="'period-timer-parameters'"
        [crmtFile]="file">
    </app-period-timer-parameters-editor>

    <app-service-number-database-editor
        *ngSwitchCase="'service-number-database'"
        [crmtFile]="file">
    </app-service-number-database-editor>

    <app-response-action-database-editor
        *ngSwitchCase="'response-action-database'"
        [crmtFile]="file">
    </app-response-action-database-editor>

    <app-fault-log-viewer
        *ngSwitchCase="'fault-log'"
        [crmtFile]="file">
    </app-fault-log-viewer>

    <app-fan-usage-log-viewer
        *ngSwitchCase="'fan-usage-log'"
        [crmtFile]="file">
    </app-fan-usage-log-viewer>

</div>