<div class="wrapper">
    <ix-content-header
        variant="Secondary"
        headerTitle="Select Schedule"
        headerSubtitle="Select the date and time of the deployment">
    </ix-content-header>
    <div class="radio-group">
        <input checked id="deployNow" name="group_1" type="radio" (change)="onChange($event)"/>
        <label for="deployNow">Deploy straight away</label>

        <input id="deployLater" name="group_1" type="radio" (change)="onChange($event)"/>
        <label for="deployLater">Deploy at a specific time</label>
    </div>
    <ix-blind 
        [ngClass]="deployNow ? 'disable' : 'enable'"
        [collapsed]="!showDatePicker" 
        (collapsedChange)="onCollapsedChange($event)"
        label="Select date / time">
        <ix-datetime-picker
            [showHour]="true"
            [showMinutes]="true"
            [showSeconds]="false"
            [range]="false"
            textSelectDate="Select"
            timeFormat="HH:mm"
            [time]="deployTime"
            [minDate]="minDate"
            [from]="selectedDate"
            (dateSelect)="dateSelect($event)">
        </ix-datetime-picker>
    </ix-blind>
</div>