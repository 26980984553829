<div class="wrapper">
    <ix-content-header
        variant="Secondary"
        [headerTitle]="headerTitle"
        [headerSubtitle]="headerSubtitle">
    </ix-content-header>

    <div class="ix-key-value-list">
        <ix-key-value-list>

            <ix-key-value *ngFor="let pair of keyPairs"
                [label]="pair.key"
                labelPosition="left"
                [value]="pair.value"
            ></ix-key-value>

        </ix-key-value-list>
    </div>
</div>