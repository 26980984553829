import { Component } from '@angular/core';
import { IxActiveModal } from '@siemens/ix-angular';

@Component({
  template: `
    <ix-modal-header> Exit without saving? </ix-modal-header>
    <ix-modal-content>
     The modified values will be lost. Do you still want to proceed?
    </ix-modal-content>
    <ix-modal-footer>
      <ix-button
        outline
        class="dismiss-modal"
        (click)="activeModal.dismiss('dismiss')"
      >
        Cancel
      </ix-button>
      <ix-button class="close-modal" (click)="activeModal.close('okay')">
        OK
      </ix-button>
    </ix-modal-footer>
  `,
})
export default class CrmtExitModalComponent {
  constructor(readonly activeModal: IxActiveModal) {}

  close() {
    this.activeModal.close('My close response');
  }
}