import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileHeaderService {
  fileHeader = {
    name :'un-named', 
    majorVersion: 0, 
    minorVersion: 0,
    versionStr: ''
  }
  constructor() { }

  public getFileHeaderValues = () => {
    return this.fileHeader
  }

  public setFileHeaderName = (fileName: any) => {
    this.fileHeader.name = fileName
  }

  public setFileHeaderVersions = (majorVersion: number, minorVersion:number, versionStr: string) => {
    this.fileHeader.majorVersion = majorVersion
    this.fileHeader.minorVersion = minorVersion
    this.fileHeader.versionStr = versionStr
  }
}
