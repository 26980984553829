<div class="wrapper">
    <ix-content-header width="100%"
        hasBackButton="false"
        headerTitle="Fleet Management"
        headerSubtitle={{contentSubtitle}}>
    </ix-content-header>

    <app-create-group
        (newOrganizationData)="newOrganizationHandler($event)"
        (newSubgroup)="newSubgroupHandler($event)"
    ></app-create-group>
    <app-fleet-tree></app-fleet-tree>
</div>  
