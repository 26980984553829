import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'details-link',
  template: `
    <fa-icon *ngIf='showIcon' (click)='link()' [icon]='icon'></fa-icon>

  `,
  styles: [
    `fa-icon {
      color: var(--theme-color-primary--active);
    }
    fa-icon:hover {
      cursor: pointer;
      color: #2196F3;
    }`
  ]
})
export class DetailsLinkComponent implements OnInit, ICellRendererAngularComp {

  public icon = faArrowRight
  public value: any
  private node: any
  public showIcon: boolean = false

  constructor(
    private router: Router) {}

  agInit(params: ICellRendererParams): void {

    this.node = params.node
    this.showIcon = params.node.data.type == 'file'
  }
  
  refresh(params: ICellRendererParams): boolean {
    return false
  }

  ngOnInit(): void {}

  link() {

    const isFile = this.node.data.type == 'file'

    if(isFile) {
      const thingName = this.node.data.deviceName
      const filePath = this.node.data.filePath
      const thingStockNumber = filePath[filePath.length - 1]

      this.router.navigate(['/fleet-management/device-details', thingName, thingStockNumber])
    }
  }
}
