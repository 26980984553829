<div class="wrapper">
    <ix-content-header width="100%"
        hasBackButton="false"
        headerTitle="Device Registration"
        headerSubtitle="Register a new device to allow it to connect to CRMT.">
    </ix-content-header>

<app-device-type-selector [fileTypeSelected]="true"
    (deviceType)="deviceTypeChanged($event)">
</app-device-type-selector>
</div>

<div class="wrapper">
    <ix-content-header
        variant="Secondary"
        headerTitle="Device Serial Number"
        headerSubtitle="Enter the serial number of the device to be registered.">
    </ix-content-header>

    <ix-input-group style="margin-bottom: 0.5rem;">
        <input 
            #serialNumberInput
            placeholder="Serial number" 
            type="text" 
            class="form-control"
            (change)="serialNumberChanged($event)"
        />
    </ix-input-group>

    <ix-button 
        [disabled]="disableStandard || invalidData"
        class="m-1" 
        variant="primary"
        (click)="registerDevice()">
        Register Device
    </ix-button>
</div>  