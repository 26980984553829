import { Component, Input, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { CertificateItem, DeviceInfoService } from 'src/app/services/device-info.service';

@Component({
  selector: 'app-certificate-view',
  templateUrl: './certificate-view.component.html',
  styleUrls: ['./certificate-view.component.css']
})
export class CertificateViewComponent implements OnInit {

  @Input() device: string | null = null

  public columnDefs: ColDef[] = [
    { headerName: "Certificate ID", field: 'id', resizable: true, width: 550 }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
  };

  // Data that gets displayed in the grid
  public rowData: CertificateItem[] = []

  constructor(
    private deviceInfo: DeviceInfoService) { }

  ngOnInit() {
    if(this.device) {
      this.deviceInfo.Certificates(this.device).subscribe((data: CertificateItem[]) => {
        this.rowData = data
      })
    }
  }
}