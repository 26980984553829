import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StyleManagerService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  addStyleToShadowRoot(shadowRoot: ShadowRoot, styles: string) {
    const styleElement = this.renderer.createElement('style');
    styleElement.textContent = styles;
    this.renderer.appendChild(shadowRoot, styleElement);
  }
}

