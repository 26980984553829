import { CrmtDecoder } from "./crmt-decoder";
import { CrmtEncoder } from "./crmt-encoder";
import { CrmtFile, CrmtHeader, ICrmtFile } from "./crmt-file";

export interface SmsDatabaseItem {
    indexNumber: number;
    textMessage: string;
    destinationNumber: string;
}

export class SmsDatabase extends CrmtFile implements ICrmtFile {

    public entries: SmsDatabaseItem[] = [];

    constructor(public header: CrmtHeader) {
        super()
    }

    public static fromRawData(buffer: ArrayBuffer): SmsDatabase {
        
        const decoder = new CrmtDecoder(buffer);

        // // Extract the common header values
        const header = decoder.getHeader()

        // Create the new object
        const thingy = new SmsDatabase(header);

        // Extract the actual entries
        const entryCount = decoder.getUint32();

        for(let e = 0; e < entryCount; e++) {

            const indexNumber = decoder.getUint16();
            const textMessage =  decoder.getLengthEncodedUtf();
            const destinationNumber = decoder.getLengthEncodedAscii();
    
            thingy.addEntry(indexNumber, textMessage, destinationNumber);
        }

        return thingy;
    }

    public toRawData(): ArrayBuffer {

        const encoder = new CrmtEncoder(10 * 1024)

        // Create the header
        encoder.setHeader(this.header)

        // Write the actual entries
        const entryCount = this.entries.length
        encoder.setUint32(entryCount);

        const entries: SmsDatabaseItem[] = []

        for(let e = 0; e < entryCount; e++) {

            const entry = this.entries[e]
            encoder.setUint16(entry.indexNumber);
            encoder.setLengthEncodedUtf(entry.textMessage);
            encoder.setLengthEncodedAscii(entry.destinationNumber);
        }

        return encoder.getFileData()
    }

    public addEntry(
        indexNumber: number,
        textMessage: string,
        destinationNumber: string): void {

        const entry: SmsDatabaseItem = {
            indexNumber,
            textMessage,
            destinationNumber
        }    

        this.entries.push(entry)
    }
}

