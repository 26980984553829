import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-radio-button-cell-renderer',
  templateUrl: './radio-button-cell-renderer.component.html',
  styleUrls: ['./radio-button-cell-renderer.component.css']
})
export class RadioButtonCellRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  onChange(event: any): void {
    this.params.node.setSelected(true, true);
  }
}

