import { CrcAlgorithm } from "./crc-algorithm";
import { CrmtHeader } from "./crmt-file";


// Core functions for decoding files
export class CrmtDecoder {

    private currentIndex = 0;
    private dataView: DataView;
    private utf16Decoder: TextDecoder
    private asciiDecoder: TextDecoder

    constructor(private buffer: ArrayBuffer) {

        this.dataView = new DataView(buffer);
        this.utf16Decoder = new TextDecoder('utf-16le')
        this.asciiDecoder = new TextDecoder('ascii')
    }

    // Check that the file has a valid CRC
    public validateCrc(): boolean {

        const embeddedCrc = this.dataView.getUint32(0, true)
        const computedCrc = CrcAlgorithm.computeCrc(this.buffer)

        // TODO: Maybe throw an exception here and make this private?

        return embeddedCrc == computedCrc
    }

    public getHeader(): CrmtHeader {

        // Check the validity of the file
        const crcPassed = this.validateCrc();

        // Extract the common header values
        const crc = this.getUint32();
        const name = this.getFixedLengthUtf(122);
        const majorVersion = this.getUint16();
        const minorVersion = this.getUint16();

        const header: CrmtHeader = {
            name,
            majorVersion,
            minorVersion
        }

        return header
    }

    // Extract a 32-bit unsigned 
    public getUint32(): number {

        const result = this.dataView.getUint32(this.currentIndex, true)
        this.currentIndex += 4

        return result
    }

    // Extract a 16-bit unsigned
    public getUint16(): number {

        const result = this.dataView.getUint16(this.currentIndex, true)
        this.currentIndex += 2

        return result
    }

    // Extract a 8-bit unsigned
    public getUint8(): number {

        const result = this.dataView.getUint8(this.currentIndex)
        this.currentIndex += 1

        return result
    }

    // Extract a 64-bit float
    public getFloat64(): number {

        const result = this.dataView.getFloat64(this.currentIndex, true)
        this.currentIndex += 8

        return result
    }

    // Extract a string from a fixed length entry
    public getFixedLengthUtf(lengthBytes: number): string {

        const slice = this.getSlice(lengthBytes)
        const fullString = this.utf16Decoder.decode(slice)
  
        // The string might be shorter than the indicated length
        // so find the actual end
        var i = 0

        while(i < lengthBytes && fullString.charCodeAt(i)) {
            i++
        }

        // And extract the true string
        const actualString = fullString.substring(0, i)

        return actualString
    }

    // Extract a length encoded ASCII string
    public getLengthEncodedAscii(): string {

        const length = this.getUint16()
        const result = this.getFixedLengthAscii(length)

        return result
    }

    // Extract a fixed length ASCII string
    public getFixedLengthAscii(length: number): string {

        const slice = this.getSlice(length)
        const result = this.asciiDecoder.decode(slice)

        return result
    }

    // Extract a length encoded UTF-16 string (little endian)
    public getLengthEncodedUtf(): string {

        const length = 2 * this.getUint16()
        const slice = this.getSlice(length)
        const result = this.utf16Decoder.decode(slice)

        return result
    }

    // Extract date and convert to a string
    public getCrDate(): string {

        const day = this.getUint8();
        const month = this.getUint8();
        let year = this.getUint8();

        if(day != 0 && month != 0) {
            year += 1990
        }

        const date = `${day}/${month}/${year}`

        return date
    }

    // Extract time and convert to a string
    public getCrTime(): string {

        const hour = this.getUint8().toString().padStart(2, '0');
        const minute = this.getUint8().toString().padStart(2, '0');
        const second = this.getUint8().toString().padStart(2, '0');

        const time = `${hour}:${minute}:${second}`

        return time
    }

    // Extract a portion of cake
    private getSlice(lengthBytes: number): ArrayBuffer {

        const bufferSlice = this.buffer.slice(
            this.currentIndex, 
            this.currentIndex + lengthBytes)

        this.currentIndex += lengthBytes

        return bufferSlice
    }
}