import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CellClickedEvent, CellValueChangedEvent, ColDef, GridApi, GridReadyEvent, RowEditingStartedEvent, RowEditingStoppedEvent, RowSelectedEvent, GridOptions, DomLayoutType } from 'ag-grid-community';
import { UusDatabase, UusDatabaseItem } from 'src/app/utils/uus-database';
import { CrmtNumericEditor } from '../../cell-editors/crmt-numeric-editor.component';
import { PhoneNumberEditor } from '../../cell-editors/phone-number-editor.component';
import { StringEditor } from '../../cell-editors/string-editor.component';
import { GenericHeaderEditorComponent } from '../generic-header-editor/generic-header-editor.component';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { EditStateService } from 'src/app/services/edit-state.service';

@Component({
  selector: 'app-uus-database-editor',
  templateUrl: './uus-database-editor.component.html',
  styleUrls: ['./uus-database-editor.component.css']
})
export class UusDatabaseEditorComponent implements OnInit {

  @Input() crmtFile?: UusDatabase
  @ViewChild(GenericHeaderEditorComponent) headerEditor?: GenericHeaderEditorComponent

  public rowData!: UusDatabaseItem[]
  public disableAddRow = true
  public disableDeleteRow = true
  public enableEditing = false
  public disableEditing = true

  private gridApi!: GridApi;
  private edited = false
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;

  public gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus: true,
  };

  public columnDefs: ColDef[] = [
    {
      checkboxSelection: true,
      sortable: false,
      resizable: false,
      filter: false,
      suppressMenu: true,
      width: 48
    },
    { 
      headerName: "Number", 
      field: 'messageNumber', 
      resizable: false,
      width: 150,
      editable: () => this.enableEditing,
      cellEditor: CrmtNumericEditor, 
      cellEditorParams: {
        min: 0,
        max: 255
      }
    },
    { 
      headerName: "Message", 
      field: 'messageText',
      width: 200, 
      resizable: true, 
      sortable: true, 
      filter: true,
      editable: () => this.enableEditing,
      cellEditor: StringEditor, 
      cellEditorParams: {
        minLength: 0,
        maxLength: 20
      }      
    },
    { 
      headerName: "Immediate", 
      field: 'immediateDisplay', 
      width: 150,
      resizable: true, 
      sortable: true, 
      filter: true,
      editable: () => this.enableEditing,
      cellEditor: CrmtNumericEditor, 
      cellEditorParams: {
        min: 0,
        max: 1
      }
    },
    { 
      headerName: "Direction", 
      field: 'direction',
      width: 150, 
      resizable: true, 
      sortable: true, 
      filter: true,
      editable: () => this.enableEditing,
      cellEditor: CrmtNumericEditor, 
      cellEditorParams: {
        min: 0,
        max: 2
      }
    },
    { 
      headerName: "Priority", 
      field: 'sendingPriority', 
      width: 150,
      resizable: true, 
      sortable: true, 
      filter: true,
      editable: () => this.enableEditing,
      cellEditor: CrmtNumericEditor, 
      cellEditorParams: {
        min: 0,
        max: 1
      }
    },
    { 
      headerName: "Destination", 
      field: 'destinationNumber',
      width: 150, 
      resizable: true, 
      sortable: true, 
      filter: true,
      editable: () => this.enableEditing,
      cellEditor: PhoneNumberEditor, 
      cellEditorParams: {
        phoneNumberType: 'phone',
        minLength: 0,
        maxLength: 20
      }      
    }
  ];

  constructor(
    private readonly permissions: PermissionsService, private editStateService: EditStateService) {}

  ngOnInit() { 

    this.permissions.getUserLevel().subscribe(level => {

      this.enableEditing = level >= UserLevel.STANDARD
      this.disableEditing = !this.enableEditing
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    
    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {
    
      this.rowData = this.crmtFile.entries
      this.initAddRow()
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  onRowSelected(e: RowSelectedEvent): void {
    
    const selectedRows = this.gridApi.getSelectedRows()
    this.disableDeleteRow = selectedRows.length == 0
  }

  public onCellValueChanged(e: CellValueChangedEvent<any, any>) {
    this.edited = true
    this.editStateService.setEditedState(true);
  }

  onRowEditingStarted(event: RowEditingStartedEvent) {
    this.disableAddRow = true
  }

  onRowEditingStopped (event: RowEditingStoppedEvent) {
    this.initAddRow()
  }

  public getData() {

    const headerEdited = this.headerEditor?.edited

    return {
      done: this.edited || headerEdited,
      fileType: 'uus-database',
      fileData: this.crmtFile
    }
  }

  public exitEditMode() {
    this.edited = false
  }

  public addRow() {
    // Add a new row to the source data and the grid
    const newRow = { 
      messageNumber: 0,
      messageText: '',
      immediateDisplay: 0,
      direction: 0,
      sendingPriority: 0,
      destinationNumber: ''
    }
    this.rowData.push(newRow)
    const result = this.gridApi.applyTransaction({
      add: [newRow],
    });

    // Allow the user to edit the row
    const rowIndex = result?.add[0].rowIndex

    if(rowIndex || rowIndex == 0) {
      this.gridApi.startEditingCell({
        rowIndex,
        colKey: 'messageNumber'
      })
    }
  }  

  public deleteRow() {

    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex

    if(index || index == 0) {
      this.rowData.splice(index, 1)
      this.gridApi.applyTransaction({remove: [selection[0].data]})
      this.edited = true
      this.disableAddRow = false
    }
  }

  public editRow() {
    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex
    this.gridApi!.setFocusedCell(index as number, "messageNumber");
    this.gridApi!.startEditingCell({
      rowIndex: index as number,
      colKey: "messageNumber",
    });
  }

  private initAddRow() {
    const maxRows = 20
    this.disableAddRow = this.rowData.length >= maxRows
  }
}
