<div class="wrapper">
    <ix-content-header width="100%"
        hasBackButton="false"
        headerTitle="Send Command"
        headerSubtitle="Send a command to one or more devices">
    </ix-content-header>

    <div class="workflow-steps">
        <ix-workflow-steps [selectedIndex]="selectedTab">
            <ix-workflow-step [status]="status[0]" (click)="navigation(0)">Command</ix-workflow-step>
            <ix-workflow-step [status]="status[1]" (click)="navigation(1)">Devices</ix-workflow-step>
            <ix-workflow-step [status]="status[2]" (click)="navigation(2)">Schedule</ix-workflow-step>
            <ix-workflow-step [status]="status[3]" (click)="navigation(3)">Review</ix-workflow-step>
        </ix-workflow-steps>
    </div>

    <div class="workflow-container">
        <app-crmt-command-selector [hidden]="selectedTab != 0"
        (getSelectedCommandType)="getCommandType($event)"></app-crmt-command-selector>
        <crmt-device-selector 
            [hidden]="selectedTab != 1"
            [hideOption]="true"
            [updateCommand]="updateCommand">
        </crmt-device-selector>
        <app-crmt-date-selector [hidden]="selectedTab != 2"></app-crmt-date-selector>
        <app-crmt-job-review 
            [hidden]="selectedTab != 3"
            headerTitle="Review Command"
            headerSubtitle="Check the details before submitting"
            [keyPairs]="reviewData">
        </app-crmt-job-review>
        <div *ngIf="!isError">
            <app-crmt-job-status 
                [hidden]="selectedTab != 4"
                [jobId]="jobId">
            </app-crmt-job-status> 
        </div>               
    </div>
    <div class="button-panel" *ngIf="!submitted; else elseBlock">
        <ix-button 
            class="m-1" 
            outline 
            variant="Primary" 
            [disabled]="selectedTab == 0 || submitted"
            (click)="previous()">
            Previous
        </ix-button>
        <ix-button 
            class="m-1" 
            [outline]="selectedTab != 3"
            variant="Primary" 
            [disabled]="submitted || disableStandard || disableNextBtn"
            (click)="next()">
            {{selectedTab == 3 ? "Submit" : "Next"}}
        </ix-button>
    </div>

    <ng-template #elseBlock>
        <div class="button-panel">
            <ix-button *ngIf="submitted"
                class="m-1" 
                [outline]="true"
                variant="Primary" 
                (click)="sendAnotherCommand()">
                Send another command
            </ix-button>
        </div>
    </ng-template> 
</div>
