import { Component, OnInit } from '@angular/core';
import { OktaAuthStateService } from '@okta/okta-angular';
import { filter, map, Observable } from 'rxjs';
import { AuthState } from '@okta/okta-auth-js';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {

  public name$!: Observable<string>;
  public email!: string
  public group!: string
  public organization!: string
  public description!: string

  constructor(
    private _oktaAuthStateService: OktaAuthStateService,
    private _apiService: ApiService) { }

  public ngOnInit(): void {
    this.name$ = this._oktaAuthStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.idToken?.claims.name ?? '')
    );

    this._apiService.test().subscribe(data => {
      this.email = data.message.email
      this.group = this.getUserLevel(data.message.group)
      this.organization = data.message.organization
    })
  }
  private getUserLevel(group: string){
    let userLevel = "NONE";
    if(group === "4"){
      userLevel = "Super user"
      this.description = "Siemens admin only."
    } else if(group === "3"){
      userLevel = "Privileged user"
      this.description = "Top level access - can add organizations and subgroups."
    } else if(group === "2"){
      userLevel = "Standard user"
      this.description = "Restricted access - can perform operations for maintenance and update."
    } else if(group === "1"){
      userLevel = "Readonly user"
      this.description = "No ability to modify any aspect of the system. View / download data only."
    }
    return userLevel;
  }
}
