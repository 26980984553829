<div class="wrapper">
    <ag-grid-angular
      class="ag-theme-alpine-dark ag-theme-ix"
      style="height: 100%; width: 100%;"
      [columnDefs]="columnDefs"
      [rowData]="rowData"
      [rowSelection]="'multiple'"
      [animateRows]="true">
    </ag-grid-angular>
</div>  


