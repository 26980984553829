<div class="wrapper">
    <ag-grid-angular
    style="width: 100%; height: 100%;"
    class="ag-theme-alpine-dark ag-theme-ix"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [treeData]="true"
    [animateRows]="true"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [getDataPath]="getDataPath"
    [getRowId]="getRowId"
    [autoGroupColumnDef]="autoGroupColumnDef"
    (rowDragEnd)="onRowDragEnd($event)"
    (gridReady)="onGridReady($event)"
  ></ag-grid-angular>
</div>  
