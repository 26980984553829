<div class="wrapper">
    <ix-content-header
        variant="Secondary"
        headerTitle="Command Type"
        headerSubtitle="Select the type of command to send">
    </ix-content-header>

    <ix-select (itemSelectionChange)="typeChanged($event)" mode="single">
        <ix-select-item 
            *ngFor="let type of commandTypes"
            [label]="type" 
            [value]="type">
        </ix-select-item>
    </ix-select>

    <div *ngIf="selectedType=='activate-software'">
        <ix-content-header
            variant="Secondary"
            headerTitle="Version Number"
            headerSubtitle="Select the version number to activate">
        </ix-content-header>

        <ix-input-group style="margin-bottom: 0.5rem;">
            <input 
                #versionInput
                placeholder="Version number" 
                type="text" 
                class="form-control" 
            />
        </ix-input-group>
    </div>
    <div *ngIf="selectedType=='cab-radio-details'">
        <ix-content-header
            variant="Secondary"
            headerTitle="Cab Radio Details"
            headerSubtitle="Enter the stock information.">
        </ix-content-header>

        <ix-input-group style="margin-bottom: 0.5rem;">
            <ix-select #stockTypeInput mode="single" value=false>
                <ix-select-item 
                    label="Engine" 
                    value=false>
                </ix-select-item>
                <ix-select-item 
                    label="Coach" 
                    value=true>
                </ix-select-item>
            </ix-select>
        
            <input 
                #stockNumberInput
                placeholder="Stock number" 
                type="text" 
                class="form-control"/>

            <input 
                #languageCodeInput
                placeholder="Language code" 
                type="text" 
                class="form-control"/>

        </ix-input-group>
    </div>

</div>

