import { Component, Input, OnInit } from '@angular/core';

export type ReviewKeyPair = {
  key: string;
  value: string | number;
}

@Component({
  selector: 'app-crmt-job-review',
  templateUrl: './crmt-job-review.component.html',
  styleUrls: ['./crmt-job-review.component.css']
})
export class CrmtJobReviewComponent implements OnInit {

  @Input() headerTitle = ""
  @Input() headerSubtitle = ""
  @Input() keyPairs!: ReviewKeyPair[]

  constructor() {}

  ngOnInit(): void {}
}
