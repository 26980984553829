import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

export type OrientationConfig = {
  x: number;
  y: number;
  z: number;
}

export type Orientation = {
  config: OrientationConfig,
  quaternion: number[]
}

export type OrientationPackageData = {
  device: string;
  roll: number;
  pitch: number;
  yaw: number;
  offset: number
  buildNo: number;
  trainClass: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrientationToolService {

  // The url for the REST API
  private apiUrl = environment.backendConfig.url

  constructor(private http: HttpClient) { }

  // Get the valid orientations for a given radio model
  public getOrientations(radioModel: string): Observable<Orientation[]> {

    const url = this.apiUrl + `orientation-positions/${radioModel}`
    const dataObs = this.http.get<any>(url);

    return dataObs.pipe(
      switchMap((response: any) => of(response.message))
    )
  }

  public createPackage(
    packageData: OrientationPackageData) {

    const url = this.apiUrl + 'orientation-package'
    const dataObs = this.http.post<any>(url, packageData);

    return dataObs
  }
}
