<div class="wrapper">
    <ag-grid-angular
      class="ag-theme-alpine-dark ag-theme-ix"
      style="height: 100%; width: 100%;"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [rowData]="rowData"
      [getDataPath]="getDataPath"
      [treeData]="true"
      [groupDefaultExpanded]="groupDefaultExpanded"
      
      [rowSelection]="rowSelection"
      [groupSelectsChildren]="true"
      [animateRows]="true"
      [pagination]="pagination"
      [paginationPageSize]="paginationPageSize"
      [domLayout]="domLayout"
      [rowClassRules]="rowClassRules"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)"
      (rowSelected)="onRowSelected($event)"
      >
    </ag-grid-angular>
</div>