import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface StockHistoryItem {
  stockNumber: string;
  timestamp: string;
}

export interface CertificateItem {
  id: string;
}


@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {

  // The url for the REST API
  private apiUrl = environment.backendConfig.url

  constructor(private http: HttpClient) { }

  public Certificates(deviceId: string): Observable<CertificateItem[]> {

    const url = this.apiUrl + `device-info/certificates/${deviceId}`
    const dataObs =  this.http.get<any>(url);

    return dataObs.pipe(
      switchMap((response: any) => of(response.message))
    )
  }

  public StockHistory(deviceId: string): Observable<StockHistoryItem[]> {

    const url = this.apiUrl + `device-info/stock-history/${deviceId}`
    const dataObs =  this.http.get<any>(url);

    return dataObs.pipe(
      switchMap((response: any) => of(response.message))
    )
  }

  public Inventory(deviceId: string): Observable<any> {

    const url = this.apiUrl + `device-info/inventory/${deviceId}`
    const dataObs =  this.http.get<any>(url);

    return dataObs.pipe(
      switchMap((response: any) => of(response.message))
    )
  }
}
