import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FileHeaderService } from 'src/app/services/file-header.service';
import { CrmtHeader } from 'src/app/utils/crmt-file';

@Component({
  selector: 'app-generic-header-editor',
  templateUrl: './generic-header-editor.component.html',
  styleUrls: ['./generic-header-editor.component.css']
})
export class GenericHeaderEditorComponent implements OnInit, OnChanges {

  @Input() header?: CrmtHeader

  public version = ''
  public edited = false

  constructor(
    private fileHeaderService: FileHeaderService
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {

    const header =changes["header"].currentValue

    this.version = header ? `${header.majorVersion}.${header.minorVersion}` : ''
  }

  public nameChange(event: Event) {

    if(this.header) {

      const newValue = (event.target as HTMLInputElement).value;
      this.header.name = newValue
      this.fileHeaderService.setFileHeaderName(newValue)
      this.edited = true
    }
  }

  public versionChange(event: Event) {

    if (this.header) {

      const newValue = (event.target as HTMLInputElement).value;
      if (newValue.includes('.')) {
        const tokens = newValue.split('.')
        this.header.majorVersion = parseInt(tokens[0])
        this.header.minorVersion = parseInt(tokens[1])
      } else {
        this.header.majorVersion = parseInt(newValue)
      } 

      this.fileHeaderService.setFileHeaderVersions(this.header.majorVersion, this.header.minorVersion, newValue)
      this.edited = true
    }
  }
}
