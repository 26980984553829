<div class="wrapper">
    <div class="model">
        <canvas id="canvas-box" 
            (mousemove)="onMouseMove($event)"
            (mouseleave)="onMouseLeave($event)">
        </canvas>
    </div>
    <div class="controls">
        <ix-icon-button
            class="m-1"
            icon="arrow-left"
            oval
            variant="primary"
            (click)="changeOrientation(false)">
        </ix-icon-button>
        {{orientationIndex + 1}}
        <ix-icon-button
            class="m-1"
            icon="arrow-right"
            oval
            variant="primary"
            (click)="changeOrientation(true)">
        </ix-icon-button>
    </div>
    <ix-input-group style="margin-bottom: 0.5rem;">
        <input 
            #antennaOffset
            placeholder="Antenna offset" 
            type="text" 
            class="form-control"
            (change)="inputChangeHandler()"/>

        <input 
            #trainClass
            placeholder="Train class" 
            type="text" 
            class="form-control"
            (change)="inputChangeHandler()"/>

    </ix-input-group>
</div>