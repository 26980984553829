import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CellValueChangedEvent, ColDef, DomLayoutType, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AttachedSystemsParameters, AttachedSystemsParametersItem } from 'src/app/utils/attached-systems-parameters';
import { PhoneNumberEditor } from '../../cell-editors/phone-number-editor.component';
import { DropdownRendererComponent } from '../../cell-editors/dropdown-editor.component';
import { GenericHeaderEditorComponent } from '../generic-header-editor/generic-header-editor.component';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { EditStateService } from 'src/app/services/edit-state.service';

@Component({
  selector: 'app-attached-systems-parameters-editor',
  templateUrl: './attached-systems-parameters-editor.component.html',
  styleUrls: ['./attached-systems-parameters-editor.component.css']
})
export class AttachedSystemsParametersEditorComponent implements OnInit {

  @Input() crmtFile?: AttachedSystemsParameters
  @ViewChild(GenericHeaderEditorComponent) headerEditor?: GenericHeaderEditorComponent

  public rowData!: AttachedSystemsParametersItem[]

  public enableEditing = false
  private edited = false
  private gridApi!: GridApi;
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;	
  public gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus: true,
  };
  public columnDefs: ColDef[] = [
    { 
      headerName: "System", 
      field: 'system', 
      width: 150,
      resizable: false
    },
    { 
      headerName: "Function Code", 
      field: 'functionCode',
      width: 150, 
      resizable: true, 
      sortable: true, 
      filter: true,
      editable: () => this.enableEditing,
      cellEditor: PhoneNumberEditor, 
      cellEditorParams: {
        phoneNumberType: 'digits-only',
        minLength: 2,
        maxLength: 2
      }
    },
    { 
      headerName: "Attached", 
      field: 'attachedFlagStr', 
      width: 200,
      resizable: false,
      editable: () => this.enableEditing,
      cellEditor: DropdownRendererComponent,
      cellEditorParams: {
        values: ['Yes', 'No'],
        cellRenderer: DropdownRendererComponent,
      }
    },
    { 
      headerName: "Registration Required", 
      field: 'registrationRequiredFlagStr', 
      width: 200,
      editable: () => this.enableEditing,
      resizable: true, 
      sortable: true, 
      filter: true,
      cellEditor: DropdownRendererComponent,
      cellEditorParams: {
        values: ['Yes', 'No'],
        cellRenderer: DropdownRendererComponent,
      }
    }
  ];

  constructor(
    private readonly permissions: PermissionsService, private editStateService: EditStateService) {}

  
  ngOnInit() { 

    this.permissions.getUserLevel().subscribe(level => {

      this.enableEditing = level >= UserLevel.STANDARD
    })
  }

  ngOnChanges(changes: SimpleChanges) {

    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {
    
      this.rowData = this.crmtFile?.entries
    }
  }

  public editRow() {
    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex
    this.gridApi!.setFocusedCell(index as number, "registrationRequiredFlagStr");
    this.gridApi!.startEditingCell({
      rowIndex: index as number,
      colKey: "registrationRequiredFlagStr",
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  public onCellValueChanged(e: CellValueChangedEvent<any, any>) {
    this.edited = true
    this.editStateService.setEditedState(true);
  }

  public getData() {

    const headerEdited = this.headerEditor?.edited

    return {
      done: this.edited || headerEdited,

      fileType: 'attached-systems-parameters',
      fileData: this.crmtFile
    }
  }

  public exitEditMode() {
    this.edited = false
  }
}

