import {     
    Component,
    ViewChild,
    ViewContainerRef} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-dropdown-renderer',
  template: `
    <select #select [(ngModel)]="selectedValue" (ngModelChange)="onValueChange()" class="ag-input-field-input" style="height: -webkit-fill-available;color: white; background-color: var(--theme-input--background)">
      <option *ngFor="let option of options" [value]="option">{{ option }}</option>
    </select>
  `,
})
export class DropdownRendererComponent implements ICellRendererAngularComp {
  params: any;
  selectedValue: any;
  options: string[] = [];
  public highlightAllOnFocus = true;
  private cancelBeforeStart = false;

  @ViewChild('select', { read: ViewContainerRef })
  public select!: ViewContainerRef;

  agInit(params: any): void {
    this.params = params;
    this.setInitialState();
    this.options = this.params.values; 
  
    this.cancelBeforeStart = false;

  }

  setInitialState() {
    this.selectedValue = this.params.value
    this.highlightAllOnFocus = true;
  }

  getValue(): string | null {
    return this.selectedValue;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  isCancelAfterEnd(): boolean {
    const value = this.refresh(this.params)
    const valid = value != undefined
    return !valid
  }

  refresh(params: any): boolean {
    this.params = params;
    this.selectedValue = this.params.value;
    this.options = this.params.values;
    return true;
  }

  onValueChange():void {
    this.params.value = this.selectedValue;
    this.params.node.setDataValue(this.params.colDef.field, this.selectedValue);
  }
}
