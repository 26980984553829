<div class="wrapper">

    <div *ngIf="details; else spin">

        <ix-kpi label="Job status" [value]="status | titlecase"></ix-kpi>
        <ix-kpi 
            label="Succeeded" 
            [value]="details.numberOfSucceededThings" 
            unit="devices">
        </ix-kpi>

        <ix-kpi 
            label="Failed" 
            [value]="details.numberOfFailedThings" 
            unit="devices" 
            [state]="details.numberOfFailedThings == '0' ? 'neutral' : 'alarm'">
        </ix-kpi>

        <ix-kpi 
            label="In progress" 
            [value]="details.numberOfInProgressThings" 
            unit="devices">
        </ix-kpi>
        
        <ix-kpi 
            label="Queued" 
            [value]="details.numberOfQueuedThings" 
            unit="devices">
        </ix-kpi>

        <ix-kpi 
            label="Timed out" 
            [value]="details.numberOfTimedOutThings" 
            unit="devices">
        </ix-kpi>

        <ix-kpi 
            label="Removed" 
            [value]="details.numberOfRemovedThings" 
            unit="devices">
        </ix-kpi>

        <ix-kpi 
            label="Rejected" 
            [value]="details.numberOfRejectedThings" 
            unit="devices">
        </ix-kpi>

        <ix-kpi 
            label="Cancelled" 
            [value]="details.numberOfCanceledThings" 
            unit="devices">
        </ix-kpi>

        <div class = "device-list-container">
            <ix-content-header
                class="mid-page"
                width="100%"
                hasBackButton="false"
                headerTitle="Device List"
                headerSubtitle="Devices associated with the Job">
            </ix-content-header>
            <div *ngIf="jobStatuses.length > 0">
                <ix-event-list *ngFor="let status of jobStatuses">
                    <ix-event-list-item color="color-primary">{{ status.key }}: {{ status.value }}
                </ix-event-list-item>
                </ix-event-list>
            </div>
            <div *ngIf="jobStatuses.length === 0">
                No job statuses available.
            </div>   
        </div>
    </div>

    <ng-template #spin>
        <div class="spinner">
            <p>Waiting for job details ...</p>
        </div>
        <div class="spinner">
            <ix-spinner size="large"></ix-spinner>
        </div>
    </ng-template>

</div>