import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CellValueChangedEvent, ColDef, GridApi, GridReadyEvent, RowEditingStartedEvent, RowEditingStoppedEvent, RowSelectedEvent, GridOptions, DomLayoutType } from 'ag-grid-community';
import { SmsDatabase, SmsDatabaseItem } from 'src/app/utils/sms-database';
import { CrmtNumericEditor } from '../../cell-editors/crmt-numeric-editor.component';
import { PhoneNumberEditor } from '../../cell-editors/phone-number-editor.component';
import { StringEditor } from '../../cell-editors/string-editor.component';
import { GenericHeaderEditorComponent } from '../generic-header-editor/generic-header-editor.component';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { EditStateService } from 'src/app/services/edit-state.service';

@Component({
  selector: 'app-sms-database-editor',
  templateUrl: './sms-database-editor.component.html',
  styleUrls: ['./sms-database-editor.component.css']
})
export class SmsDatabaseEditorComponent implements OnInit {

  @Input() crmtFile?: SmsDatabase
  @ViewChild(GenericHeaderEditorComponent) headerEditor?: GenericHeaderEditorComponent

  public rowData!: SmsDatabaseItem[]
  public disableAddRow = true
  public disableDeleteRow = true
  public enableEditing = false
  public disableEditing = true

  private gridApi!: GridApi;
  private edited = false
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;

  displayedColumns: string[] = ['index', 'number', 'message'];

  public gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus: true,
  };

  public columnDefs: ColDef[] = [
    {
      checkboxSelection: true,
      sortable: false,
      resizable: false,
      filter: false,
      suppressMenu: true,
      width: 48
    },
    { 
      headerName: "Index", 
      field: 'indexNumber', 
      resizable: false,
      editable: () => this.enableEditing,
      cellEditor: CrmtNumericEditor, 
      cellEditorParams: {
        min: 1,
        max: 100
      }
    },
    { 
      headerName: "Phone number", 
      field: 'destinationNumber', 
      editable: () => this.enableEditing,
      resizable: true, 
      sortable: true, 
      filter: true,
      cellEditor: PhoneNumberEditor, 
      cellEditorParams: {
        phoneNumberType: 'sms',
        minLength: 0,
        maxLength: 20
      }      
    },
    { 
      headerName: "Message", 
      field: 'textMessage', 
      resizable: true, 
      sortable: true, 
      filter: true ,
      editable: () => this.enableEditing,
      cellEditor: StringEditor, 
      cellEditorParams: {
        minLength: 0,
        maxLength: 160
      }      
    }
  ];

  constructor(
    private readonly permissions: PermissionsService, private editStateService: EditStateService) {}

  ngOnInit() { 

    this.permissions.getUserLevel().subscribe(level => {

      this.enableEditing = level >= UserLevel.STANDARD
      this.disableEditing = !this.enableEditing
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    
    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {

      this.rowData = this.crmtFile.entries
      this.initAddRow()
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  onRowSelected(e: RowSelectedEvent): void {
    
    const selectedRows = this.gridApi.getSelectedRows()
    this.disableDeleteRow = selectedRows.length == 0
  }

  public onCellValueChanged(e: CellValueChangedEvent<any, any>) {
    this.edited = true
    this.editStateService.setEditedState(true);
  }

  public getData() {

    const headerEdited = this.headerEditor?.edited

    return {
      done: this.edited || headerEdited,
      fileType: 'sms-database',
      fileData: this.crmtFile
    }
  }

  public exitEditMode() {
    this.edited = false
  }

  onRowEditingStarted(event: RowEditingStartedEvent) {
    this.disableAddRow = true
  }

  onRowEditingStopped (event: RowEditingStoppedEvent) {
    this.initAddRow()
  }
  
  public addRow() {
    // Add a new row to the source data and the grid
    const newRow = { indexNumber: 0, destinationNumber: '', textMessage: ''}
    this.rowData.push(newRow)
    const result = this.gridApi.applyTransaction({
      add: [newRow],
    });

    // Allow the user to edit the row
    const rowIndex = result?.add[0].rowIndex

    if(rowIndex || rowIndex == 0) {
      this.gridApi.startEditingCell({
        rowIndex,
        colKey: 'indexNumber'
      })
    }
  }  

  public deleteRow() {

    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex

    if(index || index == 0) {
      this.rowData.splice(index, 1)
      this.gridApi.applyTransaction({remove: [selection[0].data]})
      this.edited = true
      this.disableAddRow = false
    }
  }

  private initAddRow() {
    const maxRows = 100
    this.disableAddRow = this.rowData.length >= maxRows
  }

  public editRow() {
    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex
    this.gridApi!.setFocusedCell(index as number, "indexNumber");
    this.gridApi!.startEditingCell({
      rowIndex: index as number,
      colKey: "indexNumber",
    });
  }
}
