import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CellValueChangedEvent, ColDef, GridApi, GridReadyEvent, RowEditingStartedEvent, RowEditingStoppedEvent, RowSelectedEvent, GridOptions, DomLayoutType } from 'ag-grid-community';
import { StandardPhonebook, StandardPhonebookItem } from 'src/app/utils/standard-phonebook';
import { CrmtNumericEditor } from '../../cell-editors/crmt-numeric-editor.component';
import { PhoneNumberEditor } from '../../cell-editors/phone-number-editor.component';
import { StringEditor } from '../../cell-editors/string-editor.component';
import { ExtendedPhonebook } from 'src/app/utils/extended-phonebook';
import { GenericHeaderEditorComponent } from '../generic-header-editor/generic-header-editor.component';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { EditStateService } from 'src/app/services/edit-state.service';

@Component({
  selector: 'app-phonebook-editor',
  templateUrl: './phonebook-editor.component.html',
  styleUrls: ['./phonebook-editor.component.css']
})
export class PhonebookEditorComponent implements OnInit {

  @Input() crmtFile?: StandardPhonebook | ExtendedPhonebook
  @ViewChild(GenericHeaderEditorComponent) headerEditor?: GenericHeaderEditorComponent

  public disableAddRow = true
  public disableDeleteRow = true
  public rowData: StandardPhonebookItem[] = []
  public enableEditing = false
  public disableEditing = true

  private edited = false
  private gridApi!: GridApi;
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;

  public gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus: true,
  };
  public columnDefs: ColDef[] = [
    {
      checkboxSelection: true,
      sortable: false,
      resizable: false,
      filter: false,
      suppressMenu: true,
      width: 48
    },
    {
      headerName: "Sim index", 
      field: 'index', 
      resizable: false, 
      sortable: true,
      editable: () => this.enableEditing,
      cellEditor: CrmtNumericEditor, 
      cellEditorParams: {
        min: 1,
        max: 254
      }
    },
    { headerName: "Phone number", 
      field: 'number', 
      editable: () => this.enableEditing,
      resizable: true, 
      sortable: true, 
      filter: true,
      cellEditor: PhoneNumberEditor, 
      cellEditorParams: {
        minLength: 0,
        maxLength: 20
      }      
    },
    { 
      headerName: "Description", 
      field: 'description', 
      resizable: true, 
      sortable: true, 
      filter: true,
      editable: () => this.enableEditing,
      cellEditor: StringEditor, 
      cellEditorParams: {
        minLength: 0,
        maxLength: 20
      }      
    }
  ];

  constructor(
    private readonly permissions: PermissionsService, private editStateService: EditStateService) {}

  ngOnInit() { 

    this.permissions.getUserLevel().subscribe(level => {

      this.enableEditing = level >= UserLevel.STANDARD
      this.disableEditing = !this.enableEditing
    })
  }


  ngOnChanges(changes: SimpleChanges) {
    
    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {
    
      this.rowData = this.crmtFile.entries || []
      this.initAddRow()
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  onRowSelected(e: RowSelectedEvent): void {
    
    const selectedRows = this.gridApi.getSelectedRows()

    this.disableDeleteRow = selectedRows.length == 0
  }

  onRowEditingStarted(event: RowEditingStartedEvent) {
    this.disableAddRow = true
  }

  onRowEditingStopped (event: RowEditingStoppedEvent) {
    this.initAddRow()
  }

  public onCellValueChanged(e: CellValueChangedEvent<any, any>) {
    this.edited = true
    this.editStateService.setEditedState(true);
  }


  public getData() {

    const headerEdited = this.headerEditor?.edited

    return {
      done: this.edited || headerEdited,
      fileType: 'standard-phonebook',
      fileData: this.crmtFile
    }
  }

  public exitEditMode() {
    this.edited = false
  }

  public addRow() {
    // Add a new row to the source data and the grid
    const newRow = { index: 0, number: '', description: ''}
    this.rowData.push(newRow)
    const result = this.gridApi.applyTransaction({
      add: [newRow],
    });

    // Allow the user to edit the row
    const rowIndex = result?.add[0].rowIndex

    if(rowIndex || rowIndex == 0) {
      this.gridApi.startEditingCell({
        rowIndex,
        colKey: 'index'
      })
    }
  }

  public deleteRow() {

    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex

    if(index || index == 0) {
      this.rowData.splice(index, 1)
      this.gridApi.applyTransaction({remove: [selection[0].data]})
      this.edited = true
      this.disableAddRow = false
    }
  }

  public editRow() {
    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex
    this.gridApi!.setFocusedCell(index as number, "index");
    this.gridApi!.startEditingCell({
      rowIndex: index as number,
      colKey: "index",
    });
  }
  
  private initAddRow() {

    // Max for standard phonebook
    let maxRows = 254

    if(this.crmtFile instanceof ExtendedPhonebook) {

      // Max for extended phonebook
      maxRows = 1200
    }

    this.disableAddRow = this.rowData.length >= maxRows
  }
}
