<div class="wrapper">
    <ix-content-header width="100%"
        hasBackButton="false"
        headerTitle="Upload File"
        headerSubtitle="Upload a file to the Cloud">
    </ix-content-header>


    <app-local-file-selector
        (localFileSelection)="fileSelectionChanged($event)">
    </app-local-file-selector>

    <div class="button-panel">
        <ix-button 
            class="m-1" 
            [disabled]="uploadDisabled || disableStandard"
            [outline]="false"
            variant="Primary" 
            (click)="upload()">
            Upload
        </ix-button>
    </div>
</div>
