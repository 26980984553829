<app-file-type-selector 
    (fileType)="fileTypeChanged($event)">
</app-file-type-selector>

<app-device-type-selector [fileTypeSelected]="fileTypeSelected"
    (deviceType)="deviceTypeChanged($event)">
</app-device-type-selector>


<div *ngIf="enableEditOption;">
    <div *ngIf="!isLoading; else spin">
        <ix-content-header variant="Secondary" headerTitle="Deployment File" headerSubtitle="Select the file to deploy">
        </ix-content-header>

        <div class="wrapper">
            <ag-grid-angular class="ag-theme-alpine-dark ag-theme-ix" style="height: 100%; width: 100%;"
                [columnDefs]="columnDefs" [rowData]="rowData$" [rowSelection]="'single'" [animateRows]="true"
                (cellClicked)="onCellClicked($event)" [domLayout]="domLayout" [pagination]="pagination"
                [paginationPageSize]="paginationPageSize" (rowSelected)="onRowSelection($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>

<ng-template #spin>
    <div class="spinner">
        <p>Loading details ...</p>
    </div>
    <div class="spinner">
        <ix-spinner size="large"></ix-spinner>
    </div>
</ng-template>