<ix-content-header
    variant="Secondary"
    headerTitle="Target Device Type"
    headerSubtitle="Select the model number of the target devices">
</ix-content-header>

<ix-select (itemSelectionChange)="selectionChanged($event)" mode="single" [disabled]="!fileTypeSelected">
    <ix-select-item 
        *ngFor="let type of deviceTypes"
        [label]="type" 
        [value]="type">
    </ix-select-item>
</ix-select>

