import { CrmtDecoder } from "./crmt-decoder";
import { CrmtEncoder } from "./crmt-encoder";
import { CrmtFile, CrmtHeader, ICrmtFile } from "./crmt-file";


export class CabRadioParametersItem {
    public handsetVolume = 0
    public speakerVolume = 0
    public cabNoiseRating = 0
    public simPin = ''
    public dsdAttached = 0
    public secondControlPanel = 0
    public hpCradle = 0
    public displayAoC = 0
    public gpsFitted = 0
    public upsFitted = 0
    public trxInternalGain = 0
    public radioAudioReceiveLevel = 0
    public radioAudioTransmitLevel = 0
    public microphoneAudioLevel = 0
    public maximumAcceptableHdop = 0
    public textMessageDateTimeDisplay = 0
    public paAudioLevel = 0
    public dsdAlarmSounded  = 0
    public driversKeyFitted = 0
    public phonebookButtonUse = 0
    public allowForcedDeregistration = 0
    public tbrInterfaceType = 0
    public bulkRegistrationUse = 0
    public bulkDeregistrationUse = 0
    public shuntingRegistrationRequired = 0
    public commonShuntingGroupActivation = 0
    public deregistrationOnEntry = 0
    public deregistrationOnExit = 0
    public allowTrainNumberRegistration = 0
    public allowShuntingDriverRegistration = 0
    public useShuntingTeamNumber = 0
    public filterOnShuntingAreaId = 0
    public restoreRegistrationsOnExit = 0
    public trainComputerInterface = 0
}

export class CabRadioParameters extends CrmtFile implements ICrmtFile {

    public entries: CabRadioParametersItem[] = [];

    constructor(public header: CrmtHeader) {
        super()
        this.entries[0] = new CabRadioParametersItem()
    }

    public static fromRawData(buffer: ArrayBuffer): CabRadioParameters {
        
        const decoder = new CrmtDecoder(buffer);

        // // Extract the common header values
        const header = decoder.getHeader()

        // Create the new object
        const thingy = new CabRadioParameters(header);

        // Extract the actual entries (and discard)
        const entryCount = decoder.getUint32();

        const handsetVolume = decoder.getUint8();
        const speakerVolume = decoder.getUint8();
        const cabNoiseRating = decoder.getUint16();
        const simPin = decoder.getLengthEncodedAscii();
        const dsdAttached = decoder.getUint16();
        const secondControlPanel = decoder.getUint16();
        const hpCradle = decoder.getUint16();
        const displayAoC = decoder.getUint16();
        const gpsFitted = decoder.getUint16();
        const upsFitted = decoder.getUint16();
        const trxInternalGain = decoder.getUint16();
        const radioAudioReceiveLevel = decoder.getUint16();
        const radioAudioTransmitLevel = decoder.getUint16();
        const microphoneAudioLevel = decoder.getUint16();
        const maximumAcceptableHdop = decoder.getUint16();
        const textMessageDateTimeDisplay = decoder.getUint16();
        const paAudioLevel = decoder.getUint16();
        const dsdAlarmSounded = decoder.getUint16();
        const driversKeyFitted = decoder.getUint16();
        const phonebookButtonUse = decoder.getUint16();
        const allowForcedDeregistration = decoder.getUint16();
        const tbrInterfaceType = decoder.getUint16();
        const bulkRegistrationUse = decoder.getUint16();
        const bulkDeregistrationUse = decoder.getUint16();
        const shuntingRegistrationRequired = decoder.getUint16();
        const commonShuntingGroupActivation = decoder.getUint16();
        const deregistrationOnEntry = decoder.getUint16();
        const deregistrationOnExit = decoder.getUint16();
        const allowTrainNumberRegistration = decoder.getUint16();
        const allowShuntingDriverRegistration = decoder.getUint16();
        const useShuntingTeamNumber = decoder.getUint16();
        const filterOnShuntingAreaId = decoder.getUint16();
        const restoreRegistrationsOnExit = decoder.getUint16();
        const trainComputerInterface = decoder.getUint16();
        
        thingy.entries[0] = {
            handsetVolume,
            speakerVolume,
            cabNoiseRating,
            simPin,
            dsdAttached,
            secondControlPanel,
            hpCradle,
            displayAoC,
            gpsFitted,
            upsFitted,
            trxInternalGain,
            radioAudioReceiveLevel,
            radioAudioTransmitLevel,
            microphoneAudioLevel,
            maximumAcceptableHdop,
            textMessageDateTimeDisplay,
            paAudioLevel,
            dsdAlarmSounded,
            driversKeyFitted,
            phonebookButtonUse,
            allowForcedDeregistration,
            tbrInterfaceType,
            bulkRegistrationUse,
            bulkDeregistrationUse,
            shuntingRegistrationRequired,
            commonShuntingGroupActivation,
            deregistrationOnEntry,
            deregistrationOnExit,
            allowTrainNumberRegistration,
            allowShuntingDriverRegistration,
            useShuntingTeamNumber,
            filterOnShuntingAreaId,
            restoreRegistrationsOnExit,
            trainComputerInterface
        }

        return thingy;
    }

    public toRawData(): ArrayBuffer {

        const encoder = new CrmtEncoder(10 * 1024)

        // Create the header
        encoder.setHeader(this.header)

        // Write the actual entries (hardcoded to the required value)
        const entryCount = 34
        encoder.setUint32(entryCount);

        const entry = this.entries[0]
        encoder.setUint8(entry.handsetVolume)
        encoder.setUint8(entry.speakerVolume)
        encoder.setUint16(entry.cabNoiseRating)
        encoder.setLengthEncodedAscii(entry.simPin)
        encoder.setUint16(entry.dsdAttached)
        encoder.setUint16(entry.secondControlPanel)
        encoder.setUint16(entry.hpCradle)
        encoder.setUint16(entry.displayAoC)
        encoder.setUint16(entry.gpsFitted)
        encoder.setUint16(entry.upsFitted)
        encoder.setUint16(entry.trxInternalGain)
        encoder.setUint16(entry.radioAudioReceiveLevel)
        encoder.setUint16(entry.radioAudioTransmitLevel)
        encoder.setUint16(entry.microphoneAudioLevel)
        encoder.setUint16(entry.maximumAcceptableHdop)
        encoder.setUint16(entry.textMessageDateTimeDisplay)
        encoder.setUint16(entry.paAudioLevel)
        encoder.setUint16(entry.dsdAlarmSounded)
        encoder.setUint16(entry.driversKeyFitted)
        encoder.setUint16(entry.phonebookButtonUse)
        encoder.setUint16(entry.allowForcedDeregistration)
        encoder.setUint16(entry.tbrInterfaceType)
        encoder.setUint16(entry.bulkRegistrationUse)
        encoder.setUint16(entry.bulkDeregistrationUse)
        encoder.setUint16(entry.shuntingRegistrationRequired)
        encoder.setUint16(entry.commonShuntingGroupActivation)
        encoder.setUint16(entry.deregistrationOnEntry)
        encoder.setUint16(entry.deregistrationOnExit)
        encoder.setUint16(entry.allowTrainNumberRegistration)
        encoder.setUint16(entry.allowShuntingDriverRegistration)
        encoder.setUint16(entry.useShuntingTeamNumber)
        encoder.setUint16(entry.filterOnShuntingAreaId)
        encoder.setUint16(entry.restoreRegistrationsOnExit)
        encoder.setUint16(entry.trainComputerInterface)

        return encoder.getFileData()
    }

    public addEntry(item: CabRadioParametersItem): void {

        this.entries.push(item)
    }
}

