import { CrmtDecoder } from "./crmt-decoder";
import { CrmtEncoder } from "./crmt-encoder";
import { CrmtFile, CrmtHeader, ICrmtFile } from "./crmt-file";

export interface ResponseActionDatabaseItem {

    responseCode: string;
    registrationActionCode: number;
    deregistrationActionCode: number;
    interrogationActionCode: number;
}

export class ResponseActionDatabase extends CrmtFile implements ICrmtFile {

    public entries: ResponseActionDatabaseItem[] = [];

    constructor(public header: CrmtHeader) {
        super()
    }

    public static fromRawData(buffer: ArrayBuffer): ResponseActionDatabase {
        
        const decoder = new CrmtDecoder(buffer);

        // // Extract the common header values
        const header = decoder.getHeader()

        // Create the new object
        const thingy = new ResponseActionDatabase(header);

        // Extract the actual entries
        const entryCount = decoder.getUint32();

        for(let e = 0; e < entryCount; e++) {

            const responseCode = decoder.getLengthEncodedAscii();
            const registrationActionCode = decoder.getUint16();
            const deregistrationActionCode = decoder.getUint16();
            const interrogationActionCode = decoder.getUint16();

            thingy.addEntry(
                responseCode,
                registrationActionCode,
                deregistrationActionCode,
                interrogationActionCode)
        }

        return thingy;
    }

    public toRawData(): ArrayBuffer {

        const encoder = new CrmtEncoder(10 * 1024)

        // Create the header
        encoder.setHeader(this.header)

        // Write the actual entries
        const entryCount = this.entries.length
        encoder.setUint32(entryCount);

        const entries: ResponseActionDatabaseItem[] = []

        for(let e = 0; e < entryCount; e++) {

            const entry = this.entries[e]

            encoder.setLengthEncodedAscii(entry.responseCode);
            encoder.setUint16(entry.registrationActionCode);
            encoder.setUint16(entry.deregistrationActionCode);
            encoder.setUint16(entry.interrogationActionCode);
        }

        return encoder.getFileData()
    }

    public addEntry(
        responseCode: string,
        registrationActionCode: number,
        deregistrationActionCode: number,
        interrogationActionCode: number): void {

        const entry: ResponseActionDatabaseItem = {
            responseCode,
            registrationActionCode,
            deregistrationActionCode,
            interrogationActionCode
        }

        this.entries.push(entry)
    }
}

