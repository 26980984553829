import { CrmtDecoder } from "./crmt-decoder";
import { CrmtEncoder } from "./crmt-encoder";
import { CrmtFile, CrmtHeader, ICrmtFile } from "./crmt-file";

export interface UusDatabaseItem {
    messageNumber: number;
    messageText: string;
    immediateDisplay: number;
    direction: number;
    sendingPriority: number;
    destinationNumber: string;
}

export class UusDatabase extends CrmtFile implements ICrmtFile {

    public entries: UusDatabaseItem[] = [];

    constructor(public header: CrmtHeader) {
        super()
    }

    public static fromRawData(buffer: ArrayBuffer): UusDatabase {
        
        const decoder = new CrmtDecoder(buffer);

        // // Extract the common header values
        const header = decoder.getHeader()

        // Create the new object
        const thingy = new UusDatabase(header);

        // Extract the actual entries
        const entryCount = decoder.getUint32();

        for(let e = 0; e < entryCount; e++) {

            const messageNumber = decoder.getUint16();
            const messageText = decoder.getLengthEncodedUtf();
            const immediateDisplay = decoder.getUint16();
            const direction = decoder.getUint16();
            const sendingPriority = decoder.getUint16();
            const destinationNumber = decoder.getLengthEncodedAscii();

            thingy.addEntry(
                messageNumber,
                messageText,
                immediateDisplay,
                direction,
                sendingPriority,
                destinationNumber
            );
        }

        return thingy;
    }

    public toRawData(): ArrayBuffer {

        const encoder = new CrmtEncoder(10 * 1024)

        // Create the header
        encoder.setHeader(this.header)

        // Write the actual entries
        const entryCount = this.entries.length
        encoder.setUint32(entryCount);

        const entries: UusDatabaseItem[] = []

        for(let e = 0; e < entryCount; e++) {

            const entry = this.entries[e]
            encoder.setUint16(entry.messageNumber);
            encoder.setLengthEncodedUtf(entry.messageText);
            encoder.setUint16(entry.immediateDisplay);
            encoder.setUint16(entry.direction);
            encoder.setUint16(entry.sendingPriority);
            encoder.setLengthEncodedAscii(entry.destinationNumber);
        }

        return encoder.getFileData()
    }

    public addEntry(
        messageNumber: number,
        messageText: string,
        immediateDisplay: number,
        direction: number,
        sendingPriority: number,
        destinationNumber: string): void {

        const entry: UusDatabaseItem = {
            messageNumber,
            messageText,
            immediateDisplay,
            direction,
            sendingPriority,
            destinationNumber
        }    

        this.entries.push(entry)
    }
}

