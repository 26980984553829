import { CrmtDecoder } from "./crmt-decoder";
import { CrmtEncoder } from "./crmt-encoder";
import { CrmtFile, CrmtHeader, ICrmtFile } from "./crmt-file";

export interface PeriodTimerParametersItem {

    duration: number;
}

export class PeriodTimerParameters extends CrmtFile implements ICrmtFile {

    public entries: PeriodTimerParametersItem[] = [];

    constructor(public header: CrmtHeader) {
        super()
    }

    public static fromRawData(buffer: ArrayBuffer): PeriodTimerParameters {
        
        const decoder = new CrmtDecoder(buffer);

        // // Extract the common header values
        const header = decoder.getHeader()

        // Create the new object
        const thingy = new PeriodTimerParameters(header);

        // Extract the actual entries
        const entryCount = decoder.getUint32();

        for(let e = 0; e < entryCount; e++) {

            const duration = decoder.getFloat64(); 
        
            thingy.addEntry(duration);
        }

        return thingy;
    }

    public toRawData(): ArrayBuffer {

        const encoder = new CrmtEncoder(10 * 1024)

        // Create the header
        encoder.setHeader(this.header)

        // Write the actual entries
        const entryCount = this.entries.length
        encoder.setUint32(entryCount);

        const entries: PeriodTimerParametersItem[] = []

        for(let e = 0; e < entryCount; e++) {

            const entry = this.entries[e]

            encoder.setFloat64(entry.duration)
        }

        return encoder.getFileData()
    }

    public addEntry(duration: number): void {

        const entry: PeriodTimerParametersItem = {
            duration
        }    

        this.entries.push(entry)
    }
}

