<div class="wrapper">
    <ix-content-header width="100%"
        hasBackButton="false"
        headerTitle="User Profile"
        headerSubtitle="User account details">
    </ix-content-header>    

    <div class="ix-key-value-list">
        <ix-key-value-list>
            <ix-key-value
                *ngIf="name$ | async as name"
                label="User"
                labelPosition="left"
                value="{{name}}"
            ></ix-key-value>
        
            <ix-key-value
                label="Email"
                labelPosition="left"
                value="{{email}}"
            ></ix-key-value>
            <ix-icon name='about' size='16' class="icon-class icon"></ix-icon>
            <ix-key-value 
                label="Access level"
                labelPosition="left"
                value="{{group}}"
            ></ix-key-value>
            
            <ix-tooltip style="margin-top:1%" id="tooltip-1" for=".icon-class">{{description}}</ix-tooltip>
            <ix-key-value
                label="Account organisation"
                labelPosition="left"
                value="{{organization}}"
            ></ix-key-value>

        </ix-key-value-list>
    </div>
</div>