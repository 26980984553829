import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { ApiService } from 'src/app/services/api.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'details-link-ii',
  template: `
    <fa-icon *ngIf='showIcon' (click)='link()' [icon]='icon'></fa-icon>

  `,
  styles: [
    `fa-icon {
      color: var(--theme-color-primary--active);
    }
    fa-icon:hover {
      cursor: pointer;
      color: #2196F3;
    }`
  ]
})
export class DetailsLinkIIComponent implements OnInit, ICellRendererAngularComp {

  public icon = faArrowRight
  public value: any
  private node: any
  public showIcon: boolean = true

  constructor(
    private router: Router) {}

  agInit(params: ICellRendererParams): void {

    this.node = params.node
  }
  
  refresh(params: ICellRendererParams): boolean {
    return false
  }

  ngOnInit(): void {}

  link() {

    const thingName = this.node.data.name
    const thingStockNumber = this.node.data.engineNumber
    this.router.navigate(['/fleet-list/device-details', thingName, thingStockNumber])
  }
}
