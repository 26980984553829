import { CrcAlgorithm } from "./crc-algorithm";
import { CrmtHeader } from "./crmt-file";

// Core functions for encoding files
export class CrmtEncoder {

    private currentIndex = 0;
    private buffer: ArrayBuffer
    private dataView: DataView;

    constructor(bufferSize: number) {

        this.buffer = new ArrayBuffer(bufferSize);
        this.dataView = new DataView(this.buffer);
    }

    // Build the header
    public setHeader(header: CrmtHeader) {
        
        // Create the header
        this.setUint32(0);
        this.setFixedLengthUtf(header.name, 122);
        this.setUint16(header.majorVersion);
        this.setUint16(header.minorVersion);
    }

    // Insert a 32-bit unsigned
    public setUint32(value: number): void {

        this.dataView.setUint32(this.currentIndex, value, true)
        this.currentIndex += 4
    }

    // Insert a 16-bit unsigned
    public setUint16(value: number): void {

        this.dataView.setUint16(this.currentIndex, value, true)
        this.currentIndex += 2
    }

    // Insert an 8-bit unsigned
    public setUint8(value: number): void {

        value = value & 0xFF
        this.dataView.setUint8(this.currentIndex, value)
        this.currentIndex += 1
    }

    // Insert a 64-bit float
    public setFloat64(value: number): void {

        this.dataView.setFloat64(this.currentIndex, value, true)
        this.currentIndex += 8
    }

    // Insert a UTF-16 string into a fixed-length field
    public setFixedLengthUtf(value: string, lengthBytes: number): void {

        if(lengthBytes & 0x1) {
            throw new Error("Buffer length must be even.")
        }
        
        const bufLengthCharacters = lengthBytes / 2
        const strLengthCharacters = value.length

        if(strLengthCharacters > bufLengthCharacters) {
            throw new Error(`String length of ${strLengthCharacters} exceeds buffer size of ${bufLengthCharacters} `)
        }

        for(let i = 0; i < bufLengthCharacters; i++) {

            if(i < strLengthCharacters) {
                const character = value.charCodeAt(i);
                this.setUint16(character)
            }
            else {
                this.setUint16(0)
            }
        }
    }

    // Insert a length-encoded ASCII string
    public setLengthEncodedAscii(value: string): void {

        this.setUint16(value.length)
        this.setFixedLengthAscii(value.length, value)
    }

    // Insert a fixed length ASCII string
    public setFixedLengthAscii(length: number, value: string): void {

        for(let i = 0; i < length; i++) {

            let character = 0

            if(i < value.length) {
                character =  value.charCodeAt(i) & 0xFF;
            }
             
            this.setUint8(character)
        }
    }

    // Insert a length-encoded UTF-16 string (little endian)
    public setLengthEncodedUtf(value: string): void {

        const length = value.length
        this.setUint16(length)

        for(let i = 0; i < length; i++) {
            const character = value.charCodeAt(i);
            this.setUint16(character)
        }
    }

    // Get the completed file
    public getFileData(): ArrayBuffer {

        // Truncate the buffer to the end of the data
        this.buffer = this.buffer.slice(0, this.currentIndex)
        this.dataView = new DataView(this.buffer);

        // Compute and insert the CRC
        const computedCrc = CrcAlgorithm.computeCrc(this.buffer)
        this.dataView.setUint32(0, computedCrc, true)

        return this.buffer
    }
}