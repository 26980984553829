import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { StyleManagerService } from '../../services/style-manager.service';
import { EditStateService } from 'src/app/services/edit-state.service';

@Component({
  selector: 'app-file-type-selector',
  templateUrl: './file-type-selector.component.html',
  styleUrls: ['./file-type-selector.component.css']
})
export class FileTypeSelectorComponent implements OnInit {

  @Output() fileType = new EventEmitter<string>();
  @Output() originalFileType = new EventEmitter<string>();
  

  public deployableFileTypes = [
    "Cab radio parameters",
    "Attached systems parameters",
    "Train crew parameters",
    "Period timer parameters",
    "Response action database",
    "Software package"    
  ]
  public nrMode!: boolean

  constructor(private _apiService: ApiService,
    private styleManager: StyleManagerService,
    private elementRef: ElementRef,
    private editStateService: EditStateService
  ) {}

  ngOnInit(): void {

    this._apiService.getAccountInfo().subscribe(data => {
      if(data.nrMode){
        this.deployableFileTypes.unshift('Standard phonebook','Extended phonebook','UUS database')
      }else{
        this.deployableFileTypes.unshift('Standard phonebook','SMS database')
      }
    })

  }

  public selectionChanged(event: any): void {

    const selection: string = event.detail[0]
    const fileType = selection.toLowerCase().replaceAll(' ', '-')
    this.fileType.emit(fileType)
  }

  ngAfterViewInit() {
    const shadowRoot = this.elementRef.nativeElement.querySelector('ix-select').shadowRoot;
    const styles = `
      .overflow {
        max-height: 56vh !important;
      }
    `;
    this.styleManager.addStyleToShadowRoot(shadowRoot, styles);
  }

  public onItemClick(event: any): void {
    if (this.editStateService.getEditedState()) {
      const selectedItem = this.elementRef.nativeElement.querySelector('ix-select')
      this.originalFileType.emit(selectedItem.value)
    }
  }
}
