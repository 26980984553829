<div class="wrapper">
    <ix-content-header width="100%"
        hasBackButton="false"
        headerTitle="Interactive Configuration Editor"
        headerSubtitle="Retrieve, edit, and deploy configuration files">
    </ix-content-header>

    <div class="workflow-steps">
        <ix-workflow-steps [selectedIndex]="selectedTab">
            <ix-workflow-step [status]="status[0]" (click)="navigation(0)">Device</ix-workflow-step>
            <ix-workflow-step [status]="status[1]" (click)="navigation(1)">Edit</ix-workflow-step>
            <ix-workflow-step [status]="status[2]" (click)="navigation(2)">Review</ix-workflow-step>
        </ix-workflow-steps>
    </div>

    <div class="workflow-container">
        <crmt-device-selector 
            [hidden]="selectedTab != 0"
            [allowGroups]="false"
            [hideOption]="true">
        </crmt-device-selector>

        <app-crmt-file-editor 
            [hidden]="selectedTab != 1"
            [device]="device">
        </app-crmt-file-editor>

        <app-crmt-job-review 
            [hidden]="selectedTab != 2"
            headerTitle="Review Deployment"
            headerSubtitle="Check the details before submitting"
            [keyPairs]="reviewData">
        </app-crmt-job-review>

        <app-crmt-job-status 
            [hidden]="selectedTab != 3"
            [jobId]="jobId">
        </app-crmt-job-status>        
    </div>
    <div class="button-panel">
        <ix-button 
            class="m-1" 
            outline 
            variant="Primary" 
            [disabled]="selectedTab == 0 || submitted"
            (click)="previous()">
            Previous
        </ix-button>
        <ix-button 
            class="m-1" 
            [outline]="selectedTab != 2"
            variant="Primary" 
            [disabled]="submitted || disableStandard"
            (click)="next()">
            {{selectedTab == 2 ? "Deploy" : "Next"}}
        </ix-button>
    </div>
</div>
