import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-cell-renderer',
  template: `
    <fa-icon *ngIf='showIcon' (click)='delete()' [icon]='icon'></fa-icon>

  `,
  styles: [
    `fa-icon {
      color: var(--theme-color-primary--active);
    }
    fa-icon:hover {
      cursor: pointer;
      color: #2196F3;
    }`
  ]
})
export class DeleteRowComponent implements OnInit, ICellRendererAngularComp {

  public icon = faTrashCan
  public value: any
  private api!: GridApi
  private node: any
  public showIcon: boolean = false

  constructor(
    private _apiService: ApiService) {}

  agInit(params: ICellRendererParams): void {

    this.value = params.value 
    this.api = params.api
    this.node = params.node

    const type = params.node.data.type
    const empty = !params.node.allChildrenCount

    this._apiService.getAccountInfo().subscribe({
      next: (data) => {
        this.showIcon = type == 'file' ||
                        type == 'folder' && empty == true
      },
      error: (e) => {}
    })

  }
  
  refresh(params: ICellRendererParams): boolean {
    return false
  }

  ngOnInit(): void {}

  delete() {

    const isFolder = this.node.data.type == 'folder'

    if(isFolder) {
      const filePath = this.node.data.filePath
      const group = filePath[filePath.length - 1]
        this._apiService.fleetRemoveGroup(group).subscribe({
        next: (data) => {
          this.api.applyTransaction({ remove: [this.node]})
        },
        error: (e) => {
          console.log(e.message)
        }
      })
    }
    else {
      const filePath = this.node.data.filePath
      const thingName = this.node.data.deviceName
      const group = filePath[filePath.length - 2]
      this._apiService.fleetRemoveThingFromGroup(group, thingName).subscribe({
        next: (data) => {
          this.api.applyTransaction({ remove: [this.node]})
        },
        error: (e) => {
          console.log(e.message)
        }
      })
    }
  }
}
